import localforage from "localforage";
import { PostHog } from "posthog-js";

export const trackOnce = async (
  posthog: PostHog,
  key: string,
  values = {} as Record<string, unknown>
): Promise<void> => {
  try {
    const trackingKey = `tracking-${key}`;
    if (await localforage.getItem(trackingKey)) return;
    posthog.capture(key, values);
    await localforage.setItem(trackingKey, true);
  } catch (error) {
    console.error("Error tracking event:", error);
  }
};
