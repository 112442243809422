import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteProps, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "../hooks/useQuery";
import { useUserRealTime } from "../hooks/useUserRealTime";
import { AffirmTransactionStatus, CheckoutRoutes } from "../typings/enums";
import { useSurveySessionDataRealTime } from "../hooks/useSurveySessionDataRealTime";
import { usePersistStore } from "../store/persistStore";

interface CheckoutRouteProps extends RouteProps {
  component: React.ComponentType<object>;
}

const isCheckoutRoute = (pathname: string): boolean => {
  return Object.values(CheckoutRoutes).includes(pathname as CheckoutRoutes);
};

// The following routing rulles are for the following routes:
// Approval = "/approval",
// Results = "/results",
// Address = "/address",
// Payment = "/payment"
// Account = "/account"

const CheckoutRoute: React.FC<CheckoutRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const query = useQuery();
  const surveySessionId = query.get("surveySessionId") ?? "";
  const { currentUser } = useAuth();
  const user = useUserRealTime(currentUser?.uid ?? "");
  const surveySession = useSurveySessionDataRealTime(surveySessionId);
  const location = useLocation();
  const history = useHistory();
  const isDisqualified = surveySession?.answers?.disqualified;
  const { affirmTransactionStatus } = usePersistStore.getState();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user && surveySession) {
      setIsLoading(false);
    }
  }, [user, surveySession]);

  const handleRedirection = useCallback(() => {
    const isAffirmTransactionSuccessful =
      affirmTransactionStatus === AffirmTransactionStatus.CaptureSucceeded;
    const hasUserPaid =
      isAffirmTransactionSuccessful || !!user?.paymentsService;
    const hasCreatedAccount = !!user?.hasCreatedAccount;

    const shouldRedirectToAccount = (hasUserPaid: boolean) => {
      return (
        (!hasCreatedAccount &&
          location.pathname === CheckoutRoutes.Account &&
          hasUserPaid) ||
        (surveySessionId &&
          hasUserPaid &&
          isCheckoutRoute(location.pathname) &&
          location.pathname !== CheckoutRoutes.Account)
      );
    };

    const shouldRedirectToPayment = (hasUserPaid: boolean) => {
      return !hasUserPaid && location.pathname === CheckoutRoutes.Account;
    };

    const shouldRedirectToHome = () => {
      return (
        isCheckoutRoute(location.pathname) &&
        !surveySessionId &&
        surveySession &&
        !surveySession?.completed
      );
    };

    const shouldRedirectToAppointment = (
      hasCreatedAccount: boolean,
      hasUserPaid: boolean
    ) => {
      return (
        isCheckoutRoute(location.pathname) &&
        hasCreatedAccount &&
        hasUserPaid &&
        !isDisqualified
      );
    };

    const redirectToAccount = () => {
      history.replace(
        `${CheckoutRoutes.Account}?surveySessionId=${surveySessionId}`
      );
    };

    const redirectToPayment = () => {
      history.replace(
        `${CheckoutRoutes.Payment}?surveySessionId=${surveySessionId}`
      );
    };

    const redirectToHome = () => {
      history.replace("/tabs/home");
    };

    const redirectToAppointment = () => {
      history.replace("/create-appointment/0");
    };

    if (shouldRedirectToAccount(hasUserPaid)) {
      redirectToAccount();
    } else if (shouldRedirectToPayment(hasUserPaid)) {
      redirectToPayment();
    } else if (shouldRedirectToHome()) {
      redirectToHome();
    } else if (shouldRedirectToAppointment(hasCreatedAccount, hasUserPaid)) {
      redirectToAppointment();
    }
  }, [
    affirmTransactionStatus,
    user,
    location.pathname,
    surveySessionId,
    surveySession,
    isDisqualified,
    history,
  ]);

  useEffect(() => {
    if (!isLoading) {
      handleRedirection();
    }
  }, [
    isLoading,
    surveySessionId,
    user,
    surveySession,
    location.pathname,
    history,
    isDisqualified,
    handleRedirection,
  ]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default CheckoutRoute;
