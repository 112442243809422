import React, { useEffect, useState } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "../hooks/useQuery";
import { AffirmTransactionStatus, CheckoutRoutes } from "../typings/enums";
import { useSurveySessionDataRealTime } from "../hooks/useSurveySessionDataRealTime";
import { FHLoader } from "./FHLoader";
import { Offerid } from "../services/stripe";
import { usePersistStore } from "../store/persistStore";
import {
  isTransactionDeclined,
  isTransactionFailed,
} from "../utils/affirmUtils";
import { ProductNames } from "../utils/surveyUtils";

interface CheckoutAffirmRouteProps extends RouteProps {
  component: React.ComponentType<object>;
}

// The following routing rulles are for the following routes:
// Affirm Payment = "/affirm-payment",

const CheckoutAffirmRoute: React.FC<CheckoutAffirmRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const query = useQuery();
  const surveySessionId = query.get("surveySessionId") ?? "";
  const transactionStatus = query.get("transaction_status") ?? "";
  const redirectPathname =
    (query.get("redirect_pathname") as CheckoutRoutes) ?? "";

  const { currentUser } = useAuth();
  const surveySession = useSurveySessionDataRealTime(surveySessionId);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUser !== undefined && surveySession !== undefined) {
      setIsLoading(false);
    }
  }, [currentUser, surveySession]);

  const isAffirmTransactionSuccessful =
    transactionStatus === AffirmTransactionStatus.CaptureSucceeded;

  const hasUserPaid = isAffirmTransactionSuccessful;

  const isAffirmPaymentRoute = CheckoutRoutes.AffirmPayment;

  const hasAffirmTransactionFailed = isTransactionFailed(transactionStatus);

  const hasUserDeclinedAffirm = isTransactionDeclined(transactionStatus);

  // user paid with affirm
  const shouldRedirectToAccount = isAffirmPaymentRoute && hasUserPaid;

  // Render the component if not loading and no transaction status from affirm in url param
  const shouldRenderComponent =
    !isLoading && isAffirmPaymentRoute && !transactionStatus;

  // Redirect to results if transaction failed
  const shouldRedirectToPathname =
    !isLoading &&
    redirectPathname &&
    isAffirmPaymentRoute &&
    (hasAffirmTransactionFailed || hasUserDeclinedAffirm);

  const { setAffirmTransactionStatus, setSelectedMembership, offerid } =
    usePersistStore.getState();

  const renderComponent = (props: RouteProps) => {
    if (isLoading) {
      return <FHLoader />;
    }

    // tread user declines as a failed transaction
    if (hasUserDeclinedAffirm) {
      if (offerid === Offerid.Ozempic) {
        setSelectedMembership(ProductNames.OzempicBundleMonthly);
      }
      if (offerid === Offerid.Mounjaro) {
        setSelectedMembership(ProductNames.MounjaroBundleMonthly);
      }
    }

    if (transactionStatus) {
      setAffirmTransactionStatus(transactionStatus as AffirmTransactionStatus);
    }

    if (shouldRedirectToAccount) {
      return (
        <Redirect
          to={`${CheckoutRoutes.Account}?surveySessionId=${surveySessionId}&transaction_status=${transactionStatus}`}
        />
      );
    }

    if (shouldRedirectToPathname) {
      return (
        <Redirect
          to={`${redirectPathname}?surveySessionId=${surveySessionId}`}
        />
      );
    }

    if (shouldRenderComponent) {
      return <Component {...props} />;
    }

    return null;
  };

  return <Route {...rest} render={renderComponent} />;
};

export default CheckoutAffirmRoute;
