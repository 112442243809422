import {
  Consult,
  ConsultReasonForVisitEnum,
  ConsultVisitStatusEnum,
} from "@futurhealth/steadymd-api-client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  User,
} from "firebase/auth";
import FirebaseFirestore, {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../conf/firebaseConfig";
import {
  FirestoreNotification,
  FirestoreSMDEpisode,
  FirestoreSMDLabOrder,
  Medication,
  Prescription,
  SMDConsult,
  SMDEpisode,
  StripePayment,
  StripeSubscription,
  SurveyQuestion,
  SurveyQuestionCollection,
  SurveySession,
  UserProfile,
} from "../typings";
import { isPastDate } from "../utils/helpers";
import {
  CreatePatientPayload,
  IntakeAnswers,
  IntakeQuestion,
} from "../typings/steadymdTypes";
import { ConsultReason, ConsultType } from "../typings/enums";

dayjs.extend(utc);

/**
 * Creates or updates a device token in Firestore.
 * @param userId - The user's unique identifier.
 * @param deviceData - Object containing the device's token, type, and user ID.
 */
export const createOrUpdateDeviceToken = async (
  userId: string,
  deviceData: { token: string; deviceType: string }
) => {
  const deviceRef = doc(db, "devices", deviceData.token);
  try {
    const docSnap = await getDoc(deviceRef);
    if (!docSnap.exists()) {
      await setDoc(deviceRef, {
        token: deviceData.token,
        deviceType: deviceData.deviceType,
        userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } else {
      console.log(`Device ${deviceData.token} already exists`);
    }
  } catch (error) {
    console.error("Error managing device token:", error);
    throw error;
  }
};

export const genericConverter = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: FirebaseFirestore.QueryDocumentSnapshot) =>
    snap.data() as T,
});

// Function to create or update Firestore user profile
export const createOrUpdateUserProfile = async (
  user: User | null,
  userProfile: Partial<UserProfile>
): Promise<void> => {
  if (!user) throw new Error("User not authenticated");
  const userRef = doc(db, "users", user.uid).withConverter(
    genericConverter<Partial<UserProfile>>()
  );
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    await setDoc(userRef, userProfile);
  } else {
    await setDoc(
      userRef,
      { ...userProfile, updatedAt: serverTimestamp() },
      { merge: true }
    );
  }
};

export const getUserProfile = async (user: User) => {
  try {
    if (!user || !user.uid) throw new Error("User not authenticated");
    const userRef = doc(db, "users", user.uid).withConverter(
      genericConverter<Partial<UserProfile>>()
    );
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      // return user data
      return userDoc.data();
    } else {
      throw new Error("no such document");
    }
  } catch (err) {
    throw new Error(`Error getting user profile: ${err}`);
  }
};

// Function to update user profile
export const updateUserProfile = async (
  userId: string,
  data: Partial<UserProfile>
): Promise<void> => {
  const userRef = doc(db, "users", userId);
  await setDoc(
    userRef,
    {
      ...data,
      updatedAt: serverTimestamp(),
    },
    { merge: true }
  );
};

// Survey Service Functions
export const updateSurveySession = async (
  sessionId: string,
  surveyData: Partial<SurveySession>
) => {
  try {
    const sessionRef = doc(db, "surveySessions", sessionId);
    await updateDoc(sessionRef, {
      ...surveyData,
      updatedAt: serverTimestamp(),
    });
  } catch (e) {
    console.error("Error updating survey session: ", e);
    throw e;
  }
};

// TODO: this function is not used anywhere in the codebase, can it be removed?
export const updateDocument = async (
  path: string,
  pathSegments: string,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) => {
  try {
    const sessionRef = doc(db, path, pathSegments);
    await updateDoc(sessionRef, {
      [key]: value,
      updatedAt: serverTimestamp(),
    });
  } catch (e) {
    console.error(`Error updating ${path} ${pathSegments}`, e);
    throw e;
  }
};

export const saveSurveyResponse = async (
  sessionId: string,
  questionSlug: string,
  answer: string
) => {
  try {
    const sessionRef = doc(db, "surveySessions", sessionId);
    await updateDoc(sessionRef, {
      [`answers.${questionSlug}`]: answer,
      updatedAt: serverTimestamp(),
    });
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const createOrUpdateSurveySession = async (
  sessionId: string,
  sessionData: Partial<SurveySession>
): Promise<void> => {
  const sessionRef = doc(db, "surveySessions", sessionId).withConverter(
    genericConverter<Partial<SurveySession>>()
  );
  const sessionDoc = await getDoc(sessionRef);

  if (!sessionDoc.exists()) {
    await setDoc(sessionRef, sessionData);
  } else {
    await setDoc(
      sessionRef,
      { ...sessionData, updatedAt: serverTimestamp() },
      { merge: true }
    );
  }
};

export const updateRefillSession = async (
  sessionData: Partial<SurveySession>
) => {
  try {
    if (!sessionData.id) throw new Error("No session ID provided");
    const sessionRef = doc(db, "refillSessions", sessionData.id);

    await updateDoc(sessionRef, {
      ...sessionData,
      updatedAt: serverTimestamp(),
    });
  } catch (e) {
    console.error("Error updating refill session: ", e);
    throw e;
  }
};

export const getRefillSession = async (sessionId: string, userId: string) => {
  try {
    const sessionRef = doc(db, "refillSessions", sessionId).withConverter(
      genericConverter<Partial<SurveySession>>()
    );
    const sessionDoc = await getDoc(sessionRef);
    if (sessionDoc.exists()) {
      const sessionData = sessionDoc.data();
      if (sessionData?.userId === userId) {
        return sessionData;
      } else {
        throw new Error(
          "User does not have permission to access this session."
        );
      }
    } else {
      throw new Error("No such refill session!");
    }
  } catch (e) {
    console.error("Error getting refill session: ", e);
    throw e;
  }
};

export const getLatestRefillSessionByUser = async (
  userId: string,
  completed = false
) => {
  try {
    const q = query(
      collection(db, "refillSessions"),
      where("userId", "==", userId),
      where("completed", "==", completed),
      orderBy("createdAt", "desc"),
      limit(1)
    );

    const docQuery = await getDocs(q);
    if (docQuery.empty) {
      return null;
    }

    const doc = docQuery.docs[0];
    return { ...doc.data() } as SurveySession;
  } catch (e) {
    console.error("Error getting refill session: ", e);
    throw e;
  }
};

export const getOrCreateSurveySession = async (
  userId: string,
  surveyData: Partial<SurveySession> = {}
): Promise<Partial<SurveySession>> => {
  try {
    const q = query(
      collection(db, "surveySessions"),
      where("userId", "==", userId)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const sessionDoc = querySnapshot.docs[0];
      if (sessionDoc && sessionDoc.exists()) {
        return {
          id: sessionDoc.id,
          ...(sessionDoc.data() as Partial<SurveySession>),
        };
      } else {
        throw new Error("No such survey session!");
      }
    } else {
      const docRef = await addDoc(collection(db, "surveySessions"), {
        userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        ...surveyData,
      });
      const newSession = await getDoc(docRef);
      return {
        id: docRef.id,
        ...(newSession?.data() ?? {}),
      };
    }
  } catch (e) {
    console.error("Error getting or creating survey session: ", e);
    throw e;
  }
};

export const getOrCreateSurveySessionByCompleteness = async (
  userId: string,
  completed = false
): Promise<{
  id: string;
  answers: IntakeAnswers;
  user: CreatePatientPayload;
}> => {
  try {
    const q = query(
      collection(db, "surveySessions"),
      where("userId", "==", userId),
      where("completed", "==", completed)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const sessionDoc = querySnapshot.docs[0];
      if (sessionDoc && sessionDoc.exists()) {
        return {
          id: sessionDoc.id,
          answers: sessionDoc.data().answers,
          user: sessionDoc.data().user,
        };
      } else {
        throw new Error("No such survey session!");
      }
    } else {
      const docRef = await addDoc(collection(db, "surveySessions"), {
        userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        completed: false,
        answers: {},
      });
      const newSession = await getDoc(docRef);
      return {
        id: docRef.id,
        answers: newSession?.data()?.answers ?? {},
        user: newSession?.data()?.user ?? {},
      };
    }
  } catch (e) {
    console.error("Error getting or creating survey session: ", e);
    throw e;
  }
};

export const markSurveySessionCompleted = async (sessionId: string) => {
  try {
    const sessionRef = doc(db, "surveySessions", sessionId);
    await updateDoc(sessionRef, {
      completed: true,
      updatedAt: serverTimestamp(),
    });
  } catch (e) {
    console.error("Error marking survey session completed: ", e);
    throw e;
  }
};

export const getSurveySession = async (sessionId: string) => {
  try {
    const sessionRef = doc(db, "surveySessions", sessionId).withConverter(
      genericConverter<Partial<SurveySession>>()
    );
    const sessionDoc = await getDoc(sessionRef);
    if (sessionDoc.exists()) {
      return sessionDoc.data();
    } else {
      throw new Error("No such survey session!");
    }
  } catch (e) {
    console.error("Error getting survey session: ", e);
    throw e;
  }
};

export const getSurveySessionByUserId = async (
  userId: string
): Promise<SurveySession | null> => {
  try {
    const surveySessionsRef = collection(db, "surveySessions");
    const q = query(surveySessionsRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Assuming you want the first matching document
      const docSnap = querySnapshot.docs[0];
      return docSnap.data() as SurveySession;
    } else {
      console.warn("No matching documents!");
      return null;
    }
  } catch (error) {
    console.error("Error getting survey session by userId:", error);
    throw error;
  }
};

export const getSurveyQuestion = async (
  slug: string,
  index: string
): Promise<IntakeQuestion | null> => {
  try {
    const questionsCollection = collection(db, "surveys", slug, "questions");
    const q = query(
      questionsCollection,
      where("index", "==", parseInt(index ?? "0"))
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const questionDoc = querySnapshot.docs[0];
      const questionData = questionDoc.data();

      // Fetch total number of questions
      const allQuestionsSnapshot = await getDocs(questionsCollection);
      const totalQuestions = allQuestionsSnapshot.size;
      return {
        index: questionData.index,
        slug: questionData.slug,
        totalQuestions,
        question_type: questionData.question_type,
        question: questionData.question,
        choices: questionData.choices,
        description: questionData.description,
        category: questionData.category,
      };
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return null;
  }
};

export const getConsult = async (guid: string) => {
  try {
    const consultRef = doc(db, "steadymd.consults", guid).withConverter(
      genericConverter<Partial<Consult>>()
    );
    const consultDoc = await getDoc(consultRef);
    if (consultDoc.exists()) {
      return consultDoc.data();
    } else {
      throw new Error("No such consult!");
    }
  } catch (e) {
    console.error("Error getting consult: ", e);
    throw e;
  }
};

export const getFollowUpOrSideEffectConsult = async (userId: string) => {
  const q = query(
    collection(db, "steadymd.consults"),
    where("userId", "==", userId),
    where("consultType", "==", "async_messaging"),
    where("reasonForVisit", "in", [
      ConsultReason.follow_up,
      ConsultReason.side_effect,
    ]),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  // Execute the query
  const docQuery = await getDocs(q);
  if (docQuery.empty) {
    return null;
  }

  const doc = docQuery.docs[0];
  return { ...doc.data() } as SMDConsult;
};

export const getAllConsultsByType = async (
  userId: string,
  consultType: ConsultType
) => {
  const q = query(
    collection(db, "steadymd.consults"),
    where("userId", "==", userId),
    where("consultType", "==", consultType)
  );

  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return [];
  }
  return querySnapshot.docs.map((doc) => doc.data() as Consult);
};

export const checkSingleFollowupExists = async (userId: string) => {
  const q = query(
    collection(db, "steadymd.consults"),
    where("userId", "==", userId),
    where("consultType", "==", "async_messaging"),
    where("reasonForVisit", "==", "follow_up"),
    where("visitStatus", "==", ConsultVisitStatusEnum.Completed),
    limit(2)
  );

  try {
    // Execute the query
    const querySnapshot = await getDocs(q);

    // Check if exactly one document exists
    if (querySnapshot.size === 1) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking documents:", error);
    return false;
  }
};

export const getRefillConsult = async (userId: string) => {
  const q = query(
    collection(db, "steadymd.consults"),
    where("userId", "==", userId),
    where("consultType", "==", "async_messaging"),
    where("reasonForVisit", "==", "follow_up"),
    where("visitStatus", "==", ConsultVisitStatusEnum.Completed),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  // Execute the query
  const docQuery = await getDocs(q);
  if (docQuery.empty) {
    return null;
  }

  const doc = docQuery.docs[0];
  return { ...doc.data() } as SMDConsult;
};

export const getReferredOutConsult = async (userId: string) => {
  const q = query(
    collection(db, "steadymd.consults"),
    where("userId", "==", userId),
    where("visitStatus", "==", ConsultVisitStatusEnum.ReferredOut),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  const docQuery = await getDocs(q);
  if (docQuery.empty) {
    return null;
  }

  const doc = docQuery.docs[0];
  return { ...doc.data() } as SMDConsult;
};

export const getConsultByReason = async (
  userId: string,
  reason: ConsultReasonForVisitEnum
) => {
  const q = query(
    collection(db, "steadymd.consults"),
    where("userId", "==", userId),
    where("reasonForVisit", "==", reason),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  const docQuery = await getDocs(q);
  if (docQuery.empty) {
    return null;
  }

  const doc = docQuery.docs[0];
  return { ...doc.data() } as SMDConsult;
};

export const getConsultsByUserId = async (userId?: string) => {
  if (!userId) {
    throw new Error("No userId in getConsultsByUserId");
  }

  try {
    const q = query(
      collection(db, "steadymd.consults"),
      where("userId", "==", userId)
    );

    const consults = await getDocs(q);

    if (!consults.empty) {
      return consults.docs.map((doc) => doc.data() as Consult);
    } else {
      console.warn("No documents available for this userId");
      return [];
    }
  } catch (e) {
    console.error("Error getting consults by userId: ", e);
    throw e;
  }
};

export const getLatestSyncConsult = async (userId?: string) => {
  if (!userId) throw new Error("no userId provided");
  try {
    const q = query(
      collection(db, "steadymd.consults"),
      where("userId", "==", userId),
      where("consultType", "in", [
        ConsultType.scheduled_video_visit,
        ConsultType.followup_video_visit,
      ]),
      where("visitStatus", "==", ConsultVisitStatusEnum.Completed),
      orderBy("createdAt", "desc"),
      limit(1)
    );

    const consults = await getDocs(q);

    if (consults.empty) {
      return null;
    } else {
      return consults.docs[0].data() as Consult;
    }
  } catch (e) {
    console.error("Error getting latest sync consult: ", e);
    throw e;
  }
};

export const updateConsult = async (guid: string, confirmed: boolean) => {
  try {
    const consultRef = doc(db, "steadymd.consults", guid);
    await updateDoc(consultRef, {
      confirmed,
      updatedAt: serverTimestamp(),
    });
  } catch (e) {
    console.error("Error updating consult: ", e);
    throw e;
  }
};

export const updatePrescriptionConfirmedDelivery = async (
  prescriptionId: string
) => {
  try {
    const prescription = doc(db, "steadymd.prescriptions", prescriptionId);
    const formattedDate = dayjs().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

    await updateDoc(prescription, {
      confirmedDelivery: formattedDate,
      updatedAt: formattedDate,
    });
  } catch (e) {
    console.error("Error updating prescription: ", e);
    throw e;
  }
};

export const getSMDEpisode = async (episodeId: string) => {
  try {
    const episodeRef = doc(db, "steadymd.episodes", episodeId).withConverter(
      genericConverter<SMDEpisode>()
    );
    const episodeDoc = await getDoc(episodeRef);
    if (episodeDoc.exists()) {
      // return episode data
      return episodeDoc.data();
    } else {
      throw new Error("no such document");
    }
  } catch (err) {
    throw new Error(`Error getting episode: ${err}`);
  }
};

export const getLatestSMDPrescription = async (userId: string) => {
  const q = query(
    collection(db, "steadymd.prescriptions"),
    where("userId", "==", userId),
    orderBy("writtenDate", "desc"),
    limit(1)
  );

  const docQuery = await getDocs(q);
  if (docQuery.empty) {
    return null;
  }

  const doc = docQuery.docs[0];
  return { ...doc.data() } as Prescription;
};

export const getSMDPrescription = async (prescriptionId: string) => {
  try {
    const prescriptionRef = doc(
      db,
      "steadymd.prescriptions",
      prescriptionId
    ).withConverter(genericConverter<Prescription>());
    const prescriptionDoc = await getDoc(prescriptionRef);
    if (prescriptionDoc.exists()) {
      // return prescription data
      return prescriptionDoc.data();
    } else {
      console.warn("no such document");
    }
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting prescription: ${err}`);
  }
};

const getSMDPrescriptions = async (userId: string) => {
  const q = query(
    collection(db, "steadymd.prescriptions"),
    where("userId", "==", userId),
    orderBy("writtenDate", "desc")
  );

  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return querySnapshot.docs.map((doc) => doc.data());
  } else {
    console.warn("No prescriptions for this user");
    return [];
  }
};

export const getFirstDeliveredPrescription = async (userId?: string | null) => {
  if (!userId) throw new Error("no userId provided");
  try {
    const prescriptions = await getSMDPrescriptions(userId);
    if (prescriptions.length > 0) {
      // reverse array to get earlier prescriptions first. return when first delivered prescription is found
      for (const { prescriptionId } of prescriptions.reverse()) {
        const prescription = await getSMDPrescription(prescriptionId || "");
        const isDelivered =
          prescription?.confirmedDelivery ||
          (prescription?.deliveredDate &&
            isPastDate(prescription.deliveredDate));
        if (prescription && isDelivered) return prescription;
      }
    }
    return null;
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting prescription: ${err}`);
  }
};

export const getStripePayments = async (userId: string) => {
  try {
    const stripePaymentsCollection = collection(
      db,
      "stripe.payments"
    ).withConverter(genericConverter<Partial<StripePayment>>());
    const q = query(stripePaymentsCollection, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // return stripePayment data
      return querySnapshot.docs.map((doc) => doc.data());
    } else {
      throw new Error("no such document");
    }
  } catch (err) {
    console.log(err);
    throw new Error(`Error getting stripe payment: ${err}`);
  }
};

export const getStripeSubscriptions = async (userId: string) => {
  try {
    const q = query(
      collection(db, "stripe.subscriptions"),
      where("userId", "==", userId)
    );
    const docQuery = await getDocs(q);
    if (docQuery.empty) {
      return null;
    }
    return docQuery.docs.map((doc) => doc.data() as StripeSubscription);
  } catch (err) {
    console.error("Error fetching subscription: ", err);
    throw err;
  }
};

export const getAllSurveyQuestions = async (slug: string) => {
  try {
    const questionsCollection = collection(db, "surveys", slug, "questions");
    const querySnapshot = await getDocs(questionsCollection);

    if (!querySnapshot.empty) {
      const res: SurveyQuestionCollection = {};
      querySnapshot.forEach((doc) => {
        res[doc.id] = doc.data() as SurveyQuestion;
      });
      return res;
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return null;
  }
};

export const getIntake = async (userId: string) => {
  try {
    const q = query(
      collection(db, "steadymd.intakes"),
      where("userId", "==", userId),
      limit(1)
    );

    return getDocs(q);
  } catch (err) {
    console.error("Error fetching intake: ", err);
    throw err;
  }
};

export const getEpisodeByUser = async (userId?: string) => {
  if (!userId) {
    throw new Error("No userId provided");
  }
  try {
    const q = query(
      collection(db, "steadymd.episodes"),
      where("userId", "==", userId),
      limit(1)
    );
    const docQuery = await getDocs(q);
    if (docQuery.empty) {
      return null;
    }
    const doc = docQuery.docs[0];
    return { ...doc.data() } as FirestoreSMDEpisode;
  } catch (err) {
    console.error("Error fetching episode: ", err);
    throw err;
  }
};

export const setSideEffectsIntakeSubmitted = async (
  userId: string,
  value: boolean
): Promise<void> => {
  try {
    const q = query(
      collection(db, "steadymd.episodes"),
      where("userId", "==", userId),
      limit(1)
    );

    const docQuery = await getDocs(q);
    if (docQuery.empty) {
      throw new Error("No episode found for the user");
    }

    const episodeDoc = docQuery.docs[0];
    const episodeRef = doc(db, "steadymd.episodes", episodeDoc.id);

    const updatedDoc = await updateDoc(episodeRef, {
      sideEffectsIntakeSubmitted: value,
      updatedAt: serverTimestamp(),
    });

    return updatedDoc;
  } catch (error) {
    console.error("Error updating sideEffectsIntakeSubmitted: ", error);
    throw error;
  }
};

export const getLabOrdersSortedByDate = async (userId: string) => {
  try {
    const labOrdersCollection = collection(
      db,
      "steadymd.laborders"
    ).withConverter(genericConverter<FirestoreSMDLabOrder>());
    const q = query(
      labOrdersCollection,
      where("userId", "==", userId),
      orderBy("createdDate", "desc")
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // return lab order data
      return querySnapshot.docs.map((doc) => doc.data());
    } else {
      throw new Error("no such documents");
    }
  } catch (err) {
    console.log(err);
    throw new Error(`Error getting lab orders: ${err}`);
  }
};

export const getMedicationByNDC = async (ndc: string | undefined) => {
  if (!ndc) {
    throw new Error("No NDC provided");
  }
  try {
    const medicationRef = doc(db, "medications", ndc).withConverter(
      genericConverter<Medication>()
    );
    const querySnapshot = await getDoc(medicationRef);
    if (querySnapshot.exists()) {
      return querySnapshot.data();
    } else {
      console.warn("No such document");
    }
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting medication: ${err}`);
  }
};

export const getMedicationById = async (id: string | undefined) => {
  if (!id) {
    throw new Error("No id provided");
  }
  try {
    const medicationRef = doc(db, "medications", id).withConverter(
      genericConverter<Medication>()
    );
    const querySnapshot = await getDoc(medicationRef);
    if (querySnapshot.exists()) {
      return querySnapshot.data();
    } else {
      console.warn("No such document");
    }
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting medication: ${err}`);
  }
};

export const getNotificationsByUser = async (
  userId: string | undefined,
  unreadOnly: boolean
): Promise<FirestoreNotification[]> => {
  try {
    let q;

    if (unreadOnly) {
      q = query(
        collection(db, "notifications"),
        where("userId", "==", userId),
        where("read", "==", false),
        orderBy("timestampSent", "desc")
      );
    } else {
      q = query(
        collection(db, "notifications"),
        where("userId", "==", userId),
        orderBy("timestampSent", "desc")
      );
    }
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs.map(
        (doc) => doc.data() as FirestoreNotification
      );
    }
    return [];
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting messages: ${err}`);
  }
};

export const markAllMessagesRead = async (userId: string | undefined) => {
  try {
    const q = query(
      collection(db, "steadymd.messages"),
      where("userId", "==", userId),
      where("read", "==", false)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const batch = writeBatch(db);
      querySnapshot.docs.forEach((messageDoc) => {
        const docRef = doc(db, "steadymd.messages", messageDoc.id);
        batch.update(docRef, { read: true });
      });
      await batch.commit();
    }
  } catch (err) {
    console.error(err);
    throw new Error(`Error setting messages to read: ${err}`);
  }
};

export const markAllNotificationsRead = async (userId: string | undefined) => {
  try {
    const q = query(
      collection(db, "notifications"),
      where("userId", "==", userId),
      where("read", "==", false)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const batch = writeBatch(db);
      querySnapshot.docs.forEach((notificationDoc) => {
        const docRef = doc(db, "notifications", notificationDoc.id);
        batch.update(docRef, { read: true });
      });
      await batch.commit();
    } else {
      throw new Error("no such documents");
    }
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting messages: ${err}`);
  }
};

export const updateUserPassword = async (
  user: User | null,
  email: string,
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  if (!user) {
    throw new Error("User not authenticated");
  }

  // Create credential for re-authentication
  const credential = EmailAuthProvider.credential(email, currentPassword);

  try {
    // Re-authenticate the user
    await reauthenticateWithCredential(user, credential);

    // Update the user's password
    await updatePassword(user, newPassword);
  } catch (err) {
    console.error(err);
    throw new Error(`Error resetting password: ${err}`);
  }
};

export const getSurveyBySlug = async (slug: string) => {
  try {
    const surveyRef = doc(db, "surveys", slug);
    const docSnap = await getDoc(surveyRef);
    return docSnap.data();
  } catch (err) {
    console.error(err);
    throw new Error(`Error retrieving survey: ${err}`);
  }
};
