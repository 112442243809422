import { Loader } from "@mantine/core";
import React from "react";
import styles from "./FHButton.module.css";
import { colors } from "../theme/colors";

interface FHButtonProps {
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "tertiary" | "funnel" | "dark" | "danger";
  disabled?: boolean;
  fullWidth?: boolean;
  halfWidth?: boolean;
  circle?: boolean;
  rounded?: boolean;
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  dataTestId?: string;
  silentlyDisabled?: boolean;
  pulse?: boolean;
  pulseStrong?: boolean;
}

const loaderColorsMapping = {
  primary: colors.white,
  secondary: colors.white,
  tertiary: colors.blurple,
  funnel: colors.white,
  dark: colors.white,
  danger: colors.white,
};

const FHButton: React.FC<FHButtonProps> = ({
  children,
  variant = "primary",
  disabled = false,
  fullWidth = false,
  halfWidth = false,
  circle = false,
  rounded = false,
  pulse = false,
  pulseStrong = false,
  mt,
  mb,
  mr,
  ml,
  className,
  onClick,
  loading,
  dataTestId = "FHButton",
  silentlyDisabled = false,
}) => {
  const classNames = [styles.button, styles[variant], className];
  if (disabled) {
    classNames.push(styles.disabled);
  }
  if (fullWidth) {
    classNames.push(styles.fullWidth);
  }
  if (halfWidth) {
    classNames.push(styles.halfWidth);
  }
  if (circle) {
    classNames.push(styles.circleButton);
  }
  if (rounded) {
    classNames.push(styles.roundedButton);
  }
  if (pulse) {
    classNames.push(styles.pulseButton);
  }
  if (pulseStrong) {
    classNames.push(styles.pulseButtonStrong);
  }

  const style = {
    marginTop: mt,
    marginBottom: mb,
    marginRight: mr,
    marginLeft: ml,
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading || silentlyDisabled || !onClick) {
      return () => {};
    }

    onClick(event);
  };

  return (
    <button
      className={classNames.join(" ")}
      style={style}
      disabled={disabled}
      onClick={handleOnClick}
      data-testid={dataTestId}
    >
      {loading ? (
        <Loader
          color={loaderColorsMapping[variant]}
          data-testid="loading-indicator"
          size={24}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default FHButton;
