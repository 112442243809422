import { Container } from "@mantine/core";
import { colors } from "../theme/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonPage,
  IonContent,
} from "@ionic/react";
import { ToolBarProps } from "../typings";
import { StyleProp, MantineSpacing } from "@mantine/core";

export const FHContentContainer = ({
  children,
  // TODO: decide on one padding value to use for all pages
  px = "md",
  py = "xl",
  withBackground = true,
  fullHeight,
}: {
  children: ReactNode;
  title?: string;
  py?: StyleProp<MantineSpacing>;
  px?: StyleProp<MantineSpacing>;
  withBackground?: boolean;
  fullHeight?: boolean;
}) => {
  return (
    <Container
      size={600}
      px={px}
      py={py}
      pos={"relative"}
      {...(fullHeight && { h: "100%" })}
    >
      {/* partial background */}
      {withBackground && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "95vh",
            width: "100%",
            backgroundColor: colors.purpleCream,
            zIndex: -1,
          }}
        ></div>
      )}
      {children}
    </Container>
  );
};

export const FHHeader = ({
  title,
  toolBarProps,
}: {
  title: string;
  toolBarProps?: ToolBarProps;
}) => {
  const history = useHistory();
  return (
    <IonHeader mode="ios" className="header">
      <IonToolbar {...toolBarProps}>
        <IonButtons slot="start">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            icon={faChevronLeft}
            style={{ cursor: "pointer", marginLeft: "1rem" }}
            data-ph-capture-attribute-name="back-button"
          />
        </IonButtons>
        <IonTitle
          style={{
            flexGrow: 1,
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            letterSpacing: "2px",
            textTransform: "uppercase",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {title}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export const FHPage = ({
  children,
  title,
  withBackground = true,
  px = "md",
  py,
  className,
  headerToolbarProps,
  fullHeight,
}: {
  children: ReactNode;
  title: string;
  withBackground?: boolean;
  px?: string | number;
  py?: string | number;
  className?: string;
  headerToolbarProps?: ToolBarProps;
  fullHeight?: boolean;
}) => (
  <IonPage className={className}>
    <FHHeader toolBarProps={headerToolbarProps} title={title.toUpperCase()} />
    <IonContent>
      <FHContentContainer
        withBackground={withBackground}
        fullHeight={fullHeight}
        px={px}
        py={py}
      >
        {children}
      </FHContentContainer>
    </IonContent>
  </IonPage>
);
