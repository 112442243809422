export const colors = {
  purpleCream: "#F1F0FF",
  lightPurple: "#F9F9FF",
  lightPurple2: "#F2EAFF",
  black: "#0A0021",
  nearBlack: "#030212",
  nearBlack2: "#00203E",
  gray: "#858BA6",
  lightGray: "#F6F6F6",
  mediumGray: "#d4d4d4",
  disabledGray: "#C0C0CF",
  darkGray: "#5C6177",
  deepBlue: "#030212",
  blurple: "#4441d9",
  dimmedBlurple: "rgba(68, 65, 217, 0.5)",
  peach: "#FFC1AD",
  lightPeach: "#FFEFEB",
  violet: "#18184A",
  darkViolet: "#39003E",
  red: "#D94141",
  alertRed: "#FF0000",
  yellow: "#FFE600",
  yellow2: "#FFDB00",
  highlightYellow: "#FFF7AD",
  green: "#8AD54F",
  green2: "#3C9737",
  green3: "#00A66E",
  white: "#FFFFFF",
  shamrock: "#2CBBB2",
  windsor: "#6E0E8E",
  yellowOrange: "#FEB23E",
};

// Define a custom blurple color using MantineColorsTuple
export const blurple: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
] = [
  "#EBF2FF", // lightest
  "#D0D9FF",
  "#A3B3FF",
  "#738CFF",
  "#4A65FF",
  "#4441D9", // main color --blurple
  "#2C46E6",
  "#2337B3",
  "#141E6E",
  "#0D154A", // darkest
];
