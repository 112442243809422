const isTransactionFailed = (transactionCode: string): boolean => {
  const lowerCaseCode = transactionCode.toLowerCase();
  return lowerCaseCode.includes("fail");
};

const isTransactionDeclined = (transactionCode: string): boolean => {
  const lowerCaseCode = transactionCode.toLowerCase();
  return lowerCaseCode.includes("decline");
};

export { isTransactionFailed, isTransactionDeclined };
