import { trackOnce } from "../utils/tracking";
import { SURVEY_QUESTION, SURVEY_STARTED } from "../constants/posthogEvents";
import { PostHog } from "posthog-js";
import { getGoalMonths } from "./weightUtils";
import { SurveySession } from "../typings";
import { Membership, Offerid } from "../services/stripe";

export const trackSurveyQuestion = (posthog: PostHog, index: number) => {
  let surveyEvent;
  switch (true) {
    case index === 0:
      surveyEvent = SURVEY_STARTED;
      break;
    default:
      surveyEvent = `${SURVEY_QUESTION}_${index}`;
  }

  void trackOnce(posthog, surveyEvent, {});
};

export enum ProductNames {
  SemaglutideBundleMonthly = "semaglutide-bundle-monthly",
  SemaglutideBundle2Months = "semaglutide-bundle-2-months",
  SemaglutideBundle3Months = "semaglutide-bundle-3-months",
  SemaglutideBundle4Months = "semaglutide-bundle-4-months",
  SemaglutideBundle5Months = "semaglutide-bundle-5-months",
  SemaglutideBundle6Months = "semaglutide-bundle-6-months",
  SemaglutideBundle6MonthsAffirm = "semaglutide-bundle-6-months-affirm",
  OzempicBundleMonthly = "ozempic-bundle-monthly",
  OzempicBundle6Months = "ozempic-bundle-6-months",
  OzempicBundle6MonthsAffirm = "ozempic-bundle-6-months-affirm",
  MounjaroBundleMonthly = "mounjaro-bundle-monthly",
  MounjaroBundle6Months = "mounjaro-bundle-6-months",
  MounjaroBundle6MonthsAffirm = "mounjaro-bundle-6-months-affirm",
  NutritionProgram = "nutrition-program",
}

export const getProgramName = (
  surveySessionData: Partial<SurveySession>,
  selectedMembership: Membership
): string => {
  let programName = "";
  if (selectedMembership === Membership.NutritionProgram) {
    return ProductNames.NutritionProgram;
  } else if (selectedMembership === Membership.MonthlySubscription) {
    return ProductNames.SemaglutideBundleMonthly;
  } else if (selectedMembership === Membership.MultiMonthSubscription) {
    const goalWeight = surveySessionData.answers?.goal_weight ?? "0";
    const currentWeight =
      surveySessionData.answers?.height_weight?.weight ?? "0";

    const months = getGoalMonths(currentWeight, goalWeight);
    programName = getMultiMonthProgramName(months);
  }

  return programName;
};

export const getMultiMonthProgramName = (months: number): string => {
  switch (months) {
    case 2:
      return ProductNames.SemaglutideBundle2Months;
    case 3:
      return ProductNames.SemaglutideBundle3Months;
    case 4:
      return ProductNames.SemaglutideBundle4Months;
    case 5:
      return ProductNames.SemaglutideBundle5Months;
    case 6:
      return ProductNames.SemaglutideBundle6Months;
    default:
      return ProductNames.SemaglutideBundleMonthly;
  }
};

export const getProgramNameFromOfferid = (offerid: Offerid): string => {
  switch (offerid) {
    case Offerid.Ozempic:
      return ProductNames.OzempicBundle6MonthsAffirm;
    case Offerid.Sema:
      return ProductNames.SemaglutideBundle6MonthsAffirm;
    case Offerid.Mounjaro:
      return ProductNames.MounjaroBundle6MonthsAffirm;
    case Offerid.OzempicMonthly:
      return ProductNames.OzempicBundleMonthly;
    case Offerid.SemaMonthly:
      return ProductNames.SemaglutideBundleMonthly;
    case Offerid.MounjaroMonthly:
      return ProductNames.MounjaroBundleMonthly;
    default:
      throw new Error(`Unknown offerid: ${offerid}`);
  }
};
