import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useUserRealTime } from "../hooks/useUserRealTime";

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
  hidden?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  hidden = false,
  ...rest
}) => {
  const { isAuthenticated, currentUser, logoutAsCanceled } = useAuth();
  const user = useUserRealTime(currentUser?.uid ?? "");

  useEffect(() => {
    const checkSubscription = async () => {
      if (
        user &&
        (user.subscription?.status === "canceled" ||
          user.membership?.status === "inactive")
      ) {
        await logoutAsCanceled();
      }
    };
    checkSubscription();
  }, [user, logoutAsCanceled]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated && !hidden ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: { pathname: props.match.url } },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
