import { useEffect, useRef } from "react";
import * as braze from "@braze/web-sdk";

const { VITE_BRAZE_API_KEY, VITE_BRAZE_BASE_URL } = import.meta.env;

const useBraze = () => {
  const isInitialized = useRef(false);

  const initBraze = () => {
    // Initialize Braze
    braze.initialize(VITE_BRAZE_API_KEY, {
      baseUrl: VITE_BRAZE_BASE_URL,
      enableLogging: false,
    });

    // Optionally show all in-app messages without custom handling
    braze.automaticallyShowInAppMessages();

    isInitialized.current = true;

    // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
    braze.openSession();
  };

  const changeBrazeUser = (userId: string) => {
    if (isInitialized.current) {
      braze.changeUser(userId);
    } else {
      console.warn("Braze is not initialized. Call initBraze() first.");
    }
  };

  const updateBrazeUserProfile = (profile: {
    firstName?: string;
    lastName?: string;
    language?: string;
    country?: string;
    dateOfBirth?: { year: number; month: number; day: number };
    email?: string;
    gender?: braze.Genders;
    homeCity?: string;
    phoneNumber?: string;
  }) => {
    if (isInitialized.current) {
      const user = braze.getUser();
      if (profile.firstName) user?.setFirstName(profile.firstName);
      if (profile.lastName) user?.setLastName(profile.lastName);
      if (profile.language) user?.setLanguage(profile.language);
      if (profile.country) user?.setCountry(profile.country);
      if (profile.dateOfBirth)
        user?.setDateOfBirth(
          profile.dateOfBirth.year,
          profile.dateOfBirth.month,
          profile.dateOfBirth.day
        );
      if (profile.email) user?.setEmail(profile.email);
      if (profile.gender) user?.setGender(profile.gender);
      if (profile.homeCity) user?.setHomeCity(profile.homeCity);
      if (profile.phoneNumber) user?.setPhoneNumber(profile.phoneNumber);
    } else {
      console.warn("Braze is not initialized. Call initBraze() first.");
    }
  };

  const logPurchase = (
    productId: string,
    price: number,
    purchaseProperties: object = {},
    currencyCode: string = "USD",
    quantity: number = 1
  ): void => {
    if (isInitialized.current) {
      braze.logPurchase(
        productId,
        price,
        currencyCode,
        quantity,
        purchaseProperties
      );
    } else {
      console.warn("Braze is not initialized. Call initBraze() first.");
    }
  };

  const setCustomUserAttribute = (
    attributeKey: string,
    attributeValue: string[]
  ) => {
    if (isInitialized.current) {
      const user = braze.getUser();
      user?.setCustomUserAttribute(attributeKey, attributeValue);
    } else {
      console.warn("Braze is not initialized. Call initBraze() first.");
    }
  };

  useEffect(() => {
    // Initialize Braze when the component mounts
    if (!isInitialized.current) {
      initBraze();
    }
  }, []);

  return {
    initBraze,
    changeBrazeUser,
    updateBrazeUserProfile,
    setCustomUserAttribute,
    logPurchase,
  };
};

export default useBraze;
