import { useQuery } from "@tanstack/react-query";
import FuturHealthLegacyAPI from "../../utils/FuturHealthLegacyAPI";
import { useAuth } from "../../context/AuthContext";
import { Miscs } from "../../typings/enums";
import axios, { AxiosError } from "axios";

const getMealPlanSetup = async (token: string): Promise<boolean> => {
  try {
    const {
      data: { value },
    } = await FuturHealthLegacyAPI.getMisc(token, Miscs.mealPlanSetup);
    return value === "1";
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      // Handle 404 Not Found
      if (axiosError.response && axiosError.response.status === 404) {
        console.warn("Meal plan setup endpoint not found:", axiosError.message);
        // Here, decide if you want to return false, throw a custom error, or handle differently
        return false; // or throw new Error("Endpoint not found");
      } else {
        console.error("Error fetching meal plan setup:", axiosError.message);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return false; // Default to false or handle as needed
  }
};

export const useGetMealPlanSetup = () => {
  const { legacyApiUserToken } = useAuth();
  return useQuery({
    queryKey: ["legacy api", "get meal plan setup"],
    queryFn: async () => await getMealPlanSetup(legacyApiUserToken ?? ""),
    enabled: !!legacyApiUserToken,
  });
};
