import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import { createOrUpdateDeviceToken } from "./firestoreService";

export async function setupPushNotifications(
  userId?: string
): Promise<string | null> {
  if (Capacitor.getPlatform() === "web") {
    console.log("Push notifications not available on web");
    return null;
  }

  if (!Capacitor.isPluginAvailable("PushNotifications")) {
    console.log("Push notifications not available");
    return null;
  }

  let permStatus = await PushNotifications.checkPermissions();
  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    console.log("Push notification permission denied");
    return null;
  }

  await PushNotifications.register();

  // Create a single registration listener and remove it after getting token
  return new Promise((resolve) => {
    const registrationListener = PushNotifications.addListener(
      "registration",
      async (token) => {
        console.log("FCM Token:", token.value);
        if (userId) {
          await createOrUpdateDeviceToken(userId, {
            token: token.value,
            deviceType: Capacitor.getPlatform(),
          });
          resolve(token.value);
        } else {
          resolve(token.value);
        }
        // Remove the listener after getting token
        await registrationListener.then((listener) => listener.remove());
      }
    );
  });
}

export async function setupNotificationListeners() {
  if (Capacitor.getPlatform() === "web") {
    console.log("Push notification listeners not available on web");
    return;
  }
  console.log("Initializing push notification listeners...");

  await PushNotifications.addListener(
    "pushNotificationReceived",
    (notification) => {
      console.log(`in here..`);
      console.log("PUSH RECEIVED:", notification);
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (action) => {
      console.log("PUSH ACTION:", action);
    }
  );
  console.log("Push notification listeners initialized");
}

export async function setupPushListeners() {
  return setupNotificationListeners();
}

export async function requestPushPermissionAndRegister() {
  return setupPushNotifications();
}

export async function openSettings() {
  if (Capacitor.isPluginAvailable("NativeSettings")) {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification, // Specific to notification settings
      optionIOS: IOSSettings.Notifications, // Specific to notification settings
    });
  } else {
    console.warn("Native settings not available");
  }
}

export const getDeliveredNotifications = async () => {
  if (Capacitor.getPlatform() === "web") {
    console.log("Push notifications not available on web");
    return [];
  }

  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log("delivered notifications", notificationList);
  return notificationList;
};
